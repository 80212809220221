import React from 'react'
import { graphql, Link } from 'gatsby'
import { BlogSidebar } from '@components/Blog'
import { Container } from '@components/common'
import { Icon, Tag } from '@mrs-ui'
import Head from '@components/Head'

type WpCaseStudy = {
  title: string
  id: string
  slug: string
  content: any
  date: string
  acf?: {
    clientLogo: { sourceUrl: string }
    clientName: string
    industry: string
  }
  excerpt: string
}
type CaseStudy = {
  wpCaseStudy: WpCaseStudy
}

const CaseStudy = ({ data }) => {
  const { wpCaseStudy }: CaseStudy = data
  return (
    <>
      <Head title={wpCaseStudy.title} description={wpCaseStudy.excerpt} />
      <Container>
        <div className="flex flex-wrap py-8 blog-post">
          <article className="md:w-2/3 md:pr-24 w-full">
            <header>
              <div className="mb-4 flex items-center text-small">
                <Link to="/" className="hover:underline">
                  Home
                </Link>
                <Icon name="ChevronRight" className="h-4 w-4 mx-1 flex" />
                <Link to="/client-success" className="hover:underline">
                  Case Studies
                </Link>
              </div>
              <div className="mb-4">
                <img
                  src={wpCaseStudy.acf?.clientLogo.sourceUrl}
                  alt={`Logo for ${wpCaseStudy.acf?.clientName}`}
                  className="w-1/4 mb-gap-xl block"
                />
              </div>

              <h1 className="mb-4">{wpCaseStudy.title}</h1>
              <div className="mb-6 text-shade600 text-small">
                {wpCaseStudy.date}
              </div>
            </header>
            <div
              dangerouslySetInnerHTML={{
                __html: wpCaseStudy.content,
              }}
              className="pb-12"
            />
          </article>
          <aside className="md:w-1/3 w-full">
            <div className="sticky" style={{ top: '100px' }}>
              <BlogSidebar />
            </div>
          </aside>
        </div>
      </Container>
    </>
  )
}
export default CaseStudy

export const data = graphql`
  query ($slug: String!) {
    wpCaseStudy(slug: { eq: $slug }) {
      id
      slug
      title
      content
      excerpt
      date(formatString: "YYYY")

      acf {
        clientName
        clientLogo {
          sourceUrl
        }
        industry
      }
    }
  }
`
